<template>
  <div class="message-content">
    <div class="content">
      <div class="title">
        <div>
          <span class="title_left"> 捷通资讯 </span>
          <span class="title_middle"> JIETONG INFORMATION </span>
        </div>
        <!-- <div class="title_right" @click="toMsgList">查看更多 ></div> -->
      </div>
      <div class="Dot_type">
        <div style="display: flex">
          <div
            class="dotNormal"
            @click="dotTag(index, item.id, item)"
            :class="{ dotTap: dotState == index }"
            v-for="(item, index) in dotList"
            :key="index"
          >
            {{ item.columnsName }}
          </div>
        </div>
      </div>
      <div class="listAndBanner">
        <div class="listStyle">
          <!-- 轮播和二维码 -->
          <!-- v-show="showTopBanner == 0" -->
          <div
            class="listStyle_topShow"
            v-if="showTopBanner == 6 && leftBannerList.length !== 0"
          >
            <div class="listStyle_banner">
              <el-carousel
                trigger="click"
                width="460px"
                height="190px
              "
                :interval="bannerTime * 1000"
              >
                <el-carousel-item
                  v-for="(item, index) in leftBannerList"
                  :key="index"
                >
                  <div class="item_penter">
                    <img
                      @click="etcPicFn(item)"
                      width="100%"
                      height="190px"
                      :src="imgUrl + item.picImp"
                    />
                    <!-- <div class="headOne">{{ item.title }}</div> -->
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>

            <!-- <div class="listStyle_code">
              <img
                class="codeImg"
                src="../../../../assets/allImage/homePage/erweima@2x.png"
              />
              <span class="codeSpan">请扫描二维码进入小程序查看详情</span>
            </div> -->
          </div>
          <!-- 列表 -->
          <div class="listStyle_content">
            <ul>
              <li v-for="(item, index) in contentList" :key="index">
                <div class="content_title" @click="toDeails(item)">
                  <img
                    src="../../../../assets/allImage/homePage/wenzitubiao@2x.png"
                    alt=""
                    class="msgurl"
                  />
                  <span
                    v-show="item.isTop"
                    style="color: #f26363; padding: 0 0.25rem"
                    >[置顶]</span
                  >
                  <span>{{ item.title }}</span>
                </div>
                <!-- 热 -->
                <div class="HOTNEWS" v-if="item.articleMark == 'HOT'">
                  <span class="firestormRe">热</span>
                </div>

                <!-- 新 -->
                <div class="HOTNEWS" v-if="item.articleMark == 'FLATNEW'">
                  <span class="firestorm">新</span>
                </div>
                <!-- 热新 -->
                <div class="HOTNEWS" v-if="item.articleMark == 'HOTNEW'">
                  <span class="firestormRe">热</span>
                  <span class="firestorm">新</span>
                </div>
                <div class="content_time">
                  {{ item.updateTime }}
                </div>
              </li>
            </ul>
            <div
              v-show="contentList.length == 0"
              style="color: lightslategrey; text-align: center"
            >
              ----暂无数据----
            </div>
          </div>
          <div class="title_right" @click="toMsgList">查看更多 ></div>
        </div>
        <!-- 右边轮播图 -->
        <div class="bannerStyle">
          <el-carousel
            arrow="always"
            indicator-position="outside"
            ref="carousel"
            trigger="click"
            height=""
            :interval="bannerTime * 1000"
            @mouseenter.native="delHandleMouseEnter"
          >
            <el-carousel-item
              v-for="(item, index) in rightBannerList"
              :key="index"
            >
              <div class="" @click="ListOfRotographs(item)">
                <img class="rightBanner" :src="imgUrl + item.picImp" />
                <div class="carousel-text">{{ item.title }}</div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import {
  searchJieTongList,
  searchArticleMultiMedia,
} from '@/api/HomePage/JTMessage';
import { searchArticlePageList } from '@/api/HomePage/Persscenter';
import {
  headNavigationList,
  headNavigationSubcolumn,
  etcLifeInformation,
} from '../../../../api/index.js';

export default {
  name: 'JietongofficialwebsiteuiIndex',

  data() {
    return {
      // 切换标识
      dotState: 0,
      // 切换的数组
      dotList: [],
      NavigationList: [],
      // 左边的轮播图列表
      leftBannerList: [],
      // 列表
      contentList: [],
      rightBannerList: [],
      showTopBanner: 6,
      lookMoreId: 0,
      imgUrl: '',
      isRegular: 0,
      columnPageType: '',
      // 父级ID
      paramID: 0,
      argument: {
        id: '',
        columnId: '',
      },
      turnList: [],
      InformationClass: {},
      formlist: {
        pageNum: 1,
        pageSize: 10,
        columnId: '',
      },
      myProject: {},
    };
  },

  mounted() {
    this.imgUrl = process.env.VUE_APP_BASE_URL;
  },
  created() {
    this.getTopList();
    this.etcLifeInformationFn();
    this.getJieTongBanner();
  },
  computed: {
    ...mapState(['bannerTime']),
  },
  methods: {
    // etc轮播图跳转
    etcPicFn(item) {
      if (item.isExternalLink == 1) {
        window.open(item.externalLink, '_blank');
        return;
      }
      this.$store.state.dynamicParentName = {};
      this.$store.state.dynamicParentName = this.myProject;
      this.$store.state.commonNavigationList = [];
      this.$store.state.secondLevel.columnsName = '';
      this.$router.push({
        path: '/layout/companyProfile/contentDetails',
        query: {
          Carousel: item.columnId,
          id: item.id,
          columnId: item.columnId,
          isRegular: 1,
        },
      });
    },
    // etc车生活资讯
    async etcLifeInformationFn() {
      const res = await etcLifeInformation();
      if (res.data.code == 200) {
        this.leftBannerList = res.data.data;
      }
    },
    // 导航
    async getTopList() {
      const titleNav = await searchJieTongList();
      if (titleNav.status == 200) {
        if (titleNav.data && titleNav.data.length > 0) {
          this.myProject = titleNav.data[0];
          this.dotList = titleNav.data;
          // 默认第一条导航的数据id
          this.getNewList(this.dotList[0].id);
        }
      }
    },

    // 新闻列表
    async getNewList(columnId) {
      const newsList = await searchArticlePageList(columnId);
      if (newsList.data.code == 200) {
        if (newsList.data.data && newsList.data.data.length > 0) {
          if (this.showTopBanner == 6) {
            this.contentList = newsList.data.data.slice(0, 4);
          } else {
            this.contentList = newsList.data.data;
          }
        }
      }
    },
    // 新闻列表
    toDeails(item) {
      if (item.isExternalLink == 1) {
        window.open(item.externalLink, '_blank');
        return;
      }
      this.$store.state.dynamicParentName = {};
      this.$store.state.dynamicParentName = this.myProject;
      this.$store.state.commonNavigationList = [];
      this.$store.state.secondLevel.columnsName = '';
      this.$router.push({
        path: '/layout/companyProfile/contentDetails',
        query: {
          paramID: item.parentId,
          Carousel: this.myProject.id,
          id: item.id,
          columnId: this.myProject.id,
          isRegular: 1,
        },
      });
    },
    // 右侧轮播图列表
    ListOfRotographs(item) {
      if (item.isExternalLink == 1) {
        window.open(item.externalLink, '_blank');
        return;
      }
      this.$store.state.dynamicParentName = {};
      this.$store.state.dynamicParentName = this.myProject;
      this.$store.state.commonNavigationList = [];
      this.$store.state.secondLevel.columnsName = '';
      this.$router.push({
        path: '/layout/companyProfile/contentDetails',
        query: {
          Carousel: item.columnId,
          id: item.id,
          columnId: item.columnId,
          isRegular: 1,
          statusType: 0,
        },
      });
    },
    // 点击查看更多
    async toMsgList() {
      this.$store.state.dynamicParentName = {};
      this.$store.state.commonNavigationList = [];
      this.$store.state.secondLevel.columnsName = '';
      const resNav = await headNavigationSubcolumn({ id: this.myProject.id });

      // 有二级导航
      if (resNav.data.length > 0) {
        this.$store.state.secondLevel.id = resNav.data[0].id;
        this.$store.state.dynamicParentName = {};
        this.$store.state.commonNavigationList = resNav.data;
        this.$router.push({
          path: '/layout/companyProfile',
          query: {
            id: resNav.data[0].id,
            columnPageType: resNav.data[0].columnPageType,
            columnPageStyle: resNav.data[0].columnPageStyle,
            isRegular: resNav.data[0].isRegular,
            isSubStyle: resNav.data[0].isSubStyle,
            paramID: resNav.data[0].parentId,
            columnTheme: resNav.data[0].columnTheme,
          },
        });
        this.$store.state.dynamicParentName = this.myProject;
        // this.$store.state.secondLevel.columnsName = '';
      } else {
        // 没有二级导航
        this.$router.push({
          path: '/layout/companyProfile',
          query: {
            id: this.myProject.id,
            columnPageType: this.myProject.columnPageType,
            columnPageStyle: this.myProject.columnPageStyle,
            isRegular: this.myProject.isRegular,
            isSubStyle: this.myProject.isSubStyle,
            paramID: this.myProject.parentId,
            columnTheme: this.myProject.columnTheme,
          },
        });
      }
      this.$store.state.dynamicParentName = this.myProject;
    },
    // 一级导航点击事件
    dotTag(index, id, item) {
      if (item.isRegular == 6) {
        this.showTopBanner = 6;
      } else {
        this.showTopBanner = 0;
      }
      this.myProject = item;
      this.InformationClass = item;
      this.dotState = index;
      if (index !== -1) {
        this.getNewList(id);
        this.paramID = id;
        this.lookMoreId = id;
        // this.showTopBanner = 1;
      } else {
        // this.showTopBanner = 0;
      }
    },
    delHandleMouseEnter() {
      this.$refs.carousel.handleMouseEnter = () => {};
    },
    // 查看更多
    lookMore() {},
    getList() {
      // searchTravel(this.argument).then((request)=>{
      //     console.log('外接口',request);
      // })
    },

    // 获取右边轮播图
    getJieTongBanner() {
      searchArticleMultiMedia().then((request) => {
        this.rightBannerList = request.data.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.message-content {
  width: 100%;
  background-image: url('../../../../assets/allImage/homePage/jietongzixunbg@1x.png');
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  .content {
    width: 70%;
    padding: 3.125rem;
    .title {
      padding: 0.625rem 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border-bottom: 0.0625rem solid #bae9ff;
      align-items: flex-end;
      .title_left {
        font-size: 2.25rem;
        color: #0aa4ee;
      }
      .title_middle {
        color: #999999;
      }
    }
    .Dot_type {
      width: 100%;
      display: flex;
      margin: 2.5rem 0 1.25rem 0;
      .dotNormal,
      .dotTap {
        padding: 0.625rem 1.5rem;
        margin-right: 1.25rem;
        border-radius: 1.25rem;
        cursor: pointer;
      }
      .dotNormal {
        background-color: #d0d8e2;
      }
      .dotTap {
        background-color: #f277aa;
        color: white;
      }
    }
    .listAndBanner {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .listStyle {
        width: 50%;
        .listStyle_topShow {
          display: flex;
          border-radius: 0.5rem;
          margin-bottom: 0.625rem;
          /* 轮播图 */
          .listStyle_banner {
            width: 100%;
            img {
              border-radius: 0.5rem;
            }
            .listStyleimg {
              width: 100%;
              border-radius: 0.5rem;
            }
          }
          /* 二维码 */
          .listStyle_code {
            width: 7.6875rem;
            /* height: 8.75rem; */
            background-color: white;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0.5rem 0.25rem 0 0.25rem;
            border-radius: 0 0.5rem 0.5rem 0;
            .codeImg {
              width: 7rem;
              border: 0.0625rem solid #0aa4ee;
              border-radius: 0.5rem;
            }
            .codeSpan {
              font-size: 0.5rem;
              text-align: center;
              margin-top: 0.4375rem;
            }
          }
        }
        .listStyle_content {
          width: 100%;
          font-size: 1rem;
          ul {
            padding: 0;
            margin: 0;
            li {
              list-style-type: none;
              padding: 0.625rem 0;
              border-bottom: 0.0625rem solid #bdc6d1;
              display: flex;
              justify-content: space-between;
              align-items: flex-end;
              .content_title {
                width: 23.75rem;
                cursor: pointer;
                padding-left: 1.875rem;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                word-break: break-all;
                text-decoration: none;
                color: inherit;
                position: relative;
                .msgurl {
                  width: 1.25rem;
                  height: 1.25rem;
                  position: absolute;
                  left: 0;
                  top: 0;
                }
              }
              .HOTNEWS {
                .firestormRe {
                  display: inline-block;
                  background-color: #ff9502;
                  border-radius: 0.1875rem;
                  margin-right: 0.3125rem;
                  padding: 0.125rem 0.3125rem;
                  color: #fff;
                }
                .firestorm {
                  display: inline-block;
                  background-color: #f277aa;
                  border-radius: 0.1875rem;
                  margin-left: 0.3125rem;
                  padding: 0.125rem 0.3125rem;
                  color: #fff;
                }
              }
              .content_time {
                color: #999999;
                font-size: 16px;
              }
            }
          }
        }
      }
      .bannerStyle {
        cursor: pointer;
        width: 48%;
        position: relative;
        left: 1.25rem;
        top: 0;
        border-radius: 0.5rem;
        img {
          width: 100%;
          height: 18.75rem;
          border-radius: 0.5rem;
        }
        .carousel-text {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          word-break: break-all;
          position: absolute;
          bottom: 0rem;
          left: 0;
          width: 100%;
          padding: 0.375rem;
          background-color: #0000003f;
          color: #fff;
          font-size: 1rem;
          border-radius: 0rem 0rem 0.5rem 0.5rem;
          // text-align: center;
        }
      }
    }
  }
}
.item_penter {
  width: 100%;
  position: relative;
  .headOne {
    width: 100%;
    height: 2.1875rem;
    line-height: 2.1875rem;
    padding-left: 0.625rem;
    background-color: #0000003f;
    color: #fff;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: absolute;
    top: 0;
    left: 0;
  }
}
.title_right {
  width: 100%;
  height: 45px;
  color: #0aa4ee;
  cursor: pointer;
  line-height: 45px;
  text-align: right;
}
</style>
