<template>
  <!-- 底部广告轮播图 -->
  <div class="app-content">
    <div class="Special-content">
      <el-carousel
        :interval="bannerTime * 1000"
        type="card"
        style="width: 100%"
        height
        v-if="picList.length > 0"
      >
        <el-carousel-item v-for="(item, index) in picList" :key="index">
          <el-image
            @click="devFn(item)"
            style="width: 600px; height: 300px; border-radius: 1.25rem"
            :src="imgUrl + item.fileUris"
          ></el-image>
        </el-carousel-item>
      </el-carousel>
      <!-- 默认轮播图 -->
      <el-carousel
        v-else
        :interval="bannerTime * 1000"
        type="card"
        style="width: 100%"
        height
      >
        <el-carousel-item v-for="(obj, index) in defalutList" :key="index">
          <el-image
            style="max-width: 100%; border-radius: 20px"
            :src="obj.fileUris"
          ></el-image>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import { searchSpecialSubject } from '@/api/HomePage/SpecialSubject';
import { WebsiteManagementDetails } from '@/api/index';
export default {
  name: 'JietongofficialwebsiteuiIndex',
  computed: {
    ...mapState(['bannerTime']),
  },
  data() {
    return {
      defalutList: [
        {
          fileUris: require('../../../../assets/allImage/defaultCarouselChart/gongsigaikuang@1x.png'),
        },
        {
          fileUris: require('../../../../assets/allImage/defaultCarouselChart/toutu_renliziyuan@1x.png'),
        },
        {
          fileUris: require('../../../../assets/allImage/defaultCarouselChart/toutu_xinwenzhognxin@1x.png'),
        },
        {
          fileUris: require('../../../../assets/allImage/defaultCarouselChart/toutu_zhaobiaoxinxi@1x.png'),
        },
        {
          fileUris: require('../../../../assets/allImage/defaultCarouselChart/toutu_zhuantizhuanlan@1x.png'),
        },
        {
          fileUris: require('../../../../assets/allImage/defaultCarouselChart/toutu-fuwuwangdain@1x.png'),
        },
      ],
      picList: [],
      imgUrl: '',
    };
  },

  mounted() {
    this.imgUrl = process.env.VUE_APP_BASE_URL;
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      searchSpecialSubject().then((request) => {
        if (request.data.data && request.data.data.length > 0) {
          // 有数据
          this.picList = request.data.data;
        } else {
          this.picList = [];
        }
      });
    },
    navigateToLink(index) {
      // window.location.href = this.picList[index].uri;
    },
    turnToLink(item) {
      // window.open(item.impUrl);
    },
    async devFn(item) {
      const res = await WebsiteManagementDetails(item.turnColumnId);
      this.$store.state.dynamicParentName = {};
      this.$store.state.dynamicParentName = res.data.data;
      this.$store.state.commonNavigationList = [];
      this.$store.state.secondLevel.columnsName = '';
      // 判断文章里面包含有外连接
      if (item.isExternalLink == 1) {
        window.open(`${item.externalLink}`, '_blank');
        return;
      }
      // 文章
      if (item.turnType == 'turnArticle') {
        this.$router.push({
          path: '/layout/companyProfile/contentDetails',
          query: {
            Carousel: item.turnColumnId,
            id: item.articleId,
            columnId: item.turnColumnId,
            isRegular: 1,
          },
        });
      } else if (item.turnType == 'turnOut') {
        // 外连接
        window.open(`${item.impUrl}`, '_blank');
      } else if (item.turnType == 'turnColumn') {
        // 当一级导航只有单体页时
        if (
          res.data.data.columnPageType == 'MONOMER' &&
          res.data.data.columnPageStyle == ''
        ) {
          this.$router.push({
            path: '/layout/companyProfile/monomer',
            query: {
              id: res.data.data.id,
              paramID: res.data.data.parentId,
              isSubStyle: res.data.data.isSubStyle,
            },
          });
          return;
        }
        if (item.isRegular == 3) {
          // 服务网点
          this.$router.push({
            path: '/layout/serviceNetworkStation',
            query: { id: res.data.data.id },
          });
        } else if (item.isRegular == 4) {
          // 营业厅
          window.open(
            'https://etc-website.gxetc.com.cn/#/login?redirect=%2FetcBusiness',
            '_blank'
          );
        } else if (item.isRegular == 5) {
          // 跳转到etc生活
          this.$router.push({
            path: '/layout/etcguide',
            query: { id: item.id },
          });
        } else {
          // 模块
          this.$router.push({
            path: '/layout/companyProfile',
            query: {
              id: res.data.data.id,
              columnPageType: res.data.data.columnPageType,
              columnPageStyle: res.data.data.columnPageStyle,
              isRegular: res.data.data.isRegular,
              isSubStyle: res.data.data.isSubStyle,
              paramID: res.data.data.parentId,
              columnTheme: res.data.data.columnTheme,
            },
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  margin-top: 0.625rem;
}
.app-content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .Special-content {
    width: 85%;
    display: flex;
    justify-content: center;
    overflow: hidden;
  }
}
</style>
