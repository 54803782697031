<template>
  <div class="dot_content" id="content">
    <div class="content" id="moreMerchant">
      <div class="title">
        <div>
          <span class="title_left"> 网点信息 </span>
          <span class="title_middle"> BRNCH INFORMATION </span>
        </div>
        <router-link
          class="title_right"
          :to="{
            path: '/layout/serviceNetworkStation',
          }"
          >查看更多 >
        </router-link>
      </div>
      <div class="top_navigat">
        <div
          class="ETCnormal"
          @click="navigatTag(0)"
          :class="{ ETCtap: tagState == 0 }"
        >
          ETC服务网点
        </div>
        <div
          class="ETCnormal"
          @click="navigatTag(1)"
          :class="{ ETCtap: tagState == 1 }"
        >
          ETC车生活信息
        </div>
      </div>
      <!-- 网点类型 -->
      <div class="Dot_type">
        <div style="display: flex">
          <div
            class="dotNormal"
            @click="dotTag(index, item.id)"
            :class="{ dotTap: dotState == index }"
            v-for="(item, index) in dotList"
            :key="index"
          >
            {{ item.typeName }}
          </div>
        </div>
      </div>
      <!-- 列表表格 -->
      <div class="tableList">
        <el-table
          :data="tableData"
          stripe
          style="width: 100%"
          :header-cell-style="{
            background: '#0AA4EE',
            color: '#fff',
            'text-align': 'center',
          }"
          border
          height="480"
        >
          <el-table-column prop="networkName" label="网点名称" width="">
            <template slot-scope="scope">
              <span>{{
                scope.row.networkName ? scope.row.networkName : '-'
              }}</span>
              <router-link
                style="color: #0aa4ee"
                :to="{
                  path: '/layout/serviceNetworkStation',
                  query: {
                    networkName: encodeURIComponent(scope.row.networkName),
                  },
                }"
                >[查看详情]
              </router-link>
            </template>
          </el-table-column>
          <el-table-column prop="address" label="地址" width="">
          </el-table-column>
          <el-table-column prop="businessHours" label="营业时间" align="center">
            <template slot-scope="scoped">
              {{ scoped.row.businessHours ? scoped.row.businessHours : '-' }}
            </template>
          </el-table-column>
          <el-table-column prop="phone" label="联系电话" align="center">
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import {
  searchDotInformation,
  searchGetNetworkInfo,
} from '@/api/HomePage/DotInformation';
export default {
  name: 'JietongofficialwebsiteuiIndex',

  data() {
    return {
      scrollTop: 0, // 定义滚动位置变量
      miao: '', // 锚点
      argument: {
        networkType: 'ETC_SERVER',
        type: '',
      },
      // 导航切换标识
      tagState: 0,
      // 网点切换标识
      dotState: 0,
      // 网点导航列表
      dotList: [],
      ETCdotList: [
        {
          title: 'ETC停车站点',
        },
      ],
      // 表格列表
      tableData: [],
    };
  },
  // 监听网点信息传来的锚点
  watch: {
    '$route.query': {
      handler(newVal, oldVal) {
        if (newVal.name) {
          // 跳转到指点的div位置
          this.$nextTick(() => {
            document.getElementById('moreMerchant').scrollIntoView();
          });
        }
      },
    },
  },
  mounted() {},
  created() {
    this.getList();
  },
  methods: {
    getList() {
      searchDotInformation(this.argument).then((request) => {
        this.dotList = request.data.data.type;
        this.dotTag(0, this.dotList[0].id);
      });
    },
    timeString(time) {
      const dateObj = new Date(time);
      const year = dateObj.getFullYear();
      const month = dateObj.getMonth() + 1; // 注意月份是从0开始的，所以需要加1
      const day = dateObj.getDate();
      const hours = dateObj.getHours();
      const minutes = dateObj.getMinutes();
      const seconds = dateObj.getSeconds();

      const formattedTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      return formattedTime;
    },
    // 点击导航切换
    navigatTag(numb) {
      this.tagState = numb;
      if (numb == 0) {
        this.argument.networkType = 'ETC_SERVER';
      } else {
        this.argument.networkType = 'ETC_LIFE'; //车生活信息
      }
      this.getList();
    },
    // 网点点击切换
    dotTag(index, id) {
      this.dotState = index;
      this.argument.type = id;
      searchGetNetworkInfo(this.argument).then((request) => {
        console.log('字段', request);
        if (this.argument.networkType == 'ETC_SERVER') {
          this.tableData = request.data.data.financeNetworkInfoList;
        } else {
          this.tableData = request.data.data.financeEtcInfoManageList;
          this.tableData = this.tableData.map((el) => {
            return {
              networkName: el.etcInfoName,
              ...el,
            };
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.dot_content {
  width: 100%;
  background-image: url('../../../../assets/allImage/homePage/wangdainbg@1x.png');
  display: flex;
  flex-direction: column;
  align-items: center;
  .content {
    width: 70%;
    padding: 3.125rem;
    .title {
      padding: 0.625rem 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border-bottom: 0.0625rem solid #bae9ff;
      align-items: flex-end;
      .title_left {
        font-size: 2.25rem;
        color: #0aa4ee;
      }
      .title_middle {
        color: #999999;
      }
      .title_right {
        color: #0aa4ee;
      }
    }
    .top_navigat {
      margin: 2.5rem 0 1.25rem 0;
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      cursor: pointer;

      .ETCtap,
      .ETCnormal {
        width: 49.5%;
        padding: 0.875rem 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 0.25rem;
      }
      .ETCnormal {
        background-color: #d0d8e2;
      }
      .ETCtap {
        background-color: #0aa4ee;
        color: white;
      }
    }
    /* 网点类型 */
    .Dot_type {
      width: 100%;
      display: flex;
      .dotNormal,
      .dotTap {
        padding: 0.625rem 2.5rem;
        margin-right: 1.25rem;
        border-radius: 1.25rem;
        cursor: pointer;
      }
      .dotNormal {
        background-color: #d0d8e2;
      }
      .dotTap {
        background-color: #f277aa;
        color: white;
      }
    }
    /* 列表表格 */
    .tableList {
      width: 100%;
      margin: 2.5rem 0;
      ::v-deep .el-table td.el-table__cell,
      .el-table th.el-table__cell.is-leaf {
        border-bottom: 0rem;
      }
      ::v-deep .el-table--striped .el-table__body tr.el-table__row--striped td {
        background-color: #eff8ff;
      }
    }
  }
}
</style>
