import request from "/utils/request";

// 获取网点类型
export function searchDotInformation(query) {
    return request({
        url: "/finance/Reception/getNetworkType",
        method: "get",
        params:query,
    });
}

// 
export function searchGetNetworkInfo(query) {
    return request({
        url: "/finance/Reception/getNetworkInfo",
        method: "get",
        params:query,
    });
}