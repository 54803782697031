import request from '/utils/request';

// 首页快捷导航接口调用
export function searchShortcutList() {
  return request({
    url: '/finance/Reception/shortcutList',
    method: 'get',
  });
}

// 首页轮播图接口调用
export function searchBanner() {
  return request({
    url: '/finance/Reception/bannerlist',
    method: 'get',
  });
}

// 快捷导航
export function QuickNavigation() {
  return request({
    url: '/finance/Reception/shortcutList',
    method: 'get',
  });
}
