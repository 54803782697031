<template>
  <!-- 首页 -->
  <div class="app-content">
      <FixedIcon style="z-index:3"></FixedIcon>

    <banner></banner>
    <JTMessage></JTMessage>
    <DotInformation></DotInformation>
    <SpecialSubject></SpecialSubject>
    <BottomField></BottomField>
    <!-- <FixedIcon></FixedIcon> -->
  </div>
</template>

<script>
import FixedIcon from '@/layout/components/HomePage/FixedIcon/index.vue';
import banner from '@/layout/components/HomePage/banner/index.vue';
import JTMessage from '@/layout/components/HomePage/JTMessage/index.vue';
import BottomField from '@/layout/components/HomePage/BottomField/index.vue';
import SpecialSubject from '@/layout/components/HomePage/SpecialSubject/index.vue';
import DotInformation from '@/layout/components/HomePage/DotInformation/index.vue';
// import FixedIcon from '@/layout/components/HomePage/FixedIcon/index.vue';

export default {
  components: {
    banner,
    FixedIcon,
    BottomField,
    SpecialSubject,
    DotInformation,
    // FixedIcon,
    JTMessage,
  },
  data() {
    return {
      screenWidth: 0,
      screenHeight: 0,
      activeIndex: '1',
      activeIndex2: '1',
      navigatList: [
        { name: '首页' },
        { name: '公司概况' },
        { name: '网上营业厅' },
        { name: 'ETC车生活资讯' },
        { name: '新闻中心' },
        { name: '人力资源' },
        { name: '招标信息' },
        { name: '专题专栏' },
      ],
      // 搜索
      topInput: '',
    };
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    updateScreenSize() {
      this.screenWidth =
        window.innerWidth || document.documentElement.clientWidth;
      this.screenHeight =
        window.innerHeight || document.documentElement.clientHeight;
      console.log('this.screenWidth', this.screenWidth);
      console.log('this.screenHeight', this.screenHeight);
    },
  },
  mounted() {
    this.updateScreenSize();
    window.addEventListener('resize', this.updateScreenSize);
  },
};
</script>
<style lang="scss" scoped>
.app-content {
  position: relative;
  width: 100%;

  // 导航头
  .navigatMenu {
    position: fixed;
    overflow: hidden;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99999999999;
    .navigatMenu-content {
      display: flex;
      align-items: center;
    }
    .el-menu-demo {
      height: 5rem;
      display: flex;
      background-color: rgba(23, 23, 23, 0.5);
      align-items: center;
      justify-content: space-evenly;
      border-bottom: 0rem;

      // padding: 0.625rem 0;
      ::v-deep .el-submenu__title {
        background-color: rgba(23, 23, 23, 0.2);
      }
      .el-menu-item {
        height: 5rem;
        display: flex;
        align-items: center;
      }
      // 点击效果
      .el-menu-item.is-active {
        background-color: rgba(23, 23, 23, 0.2);
        border-bottom: 0.125rem solid #0aa4ee;
      }
      // 悬停时背景色
      .el-menu-item:hover {
        background-color: rgba(23, 23, 23, 0.2);
        color: #0aa4ee !important;
      }
    }
    .el-input {
      width: 12.5rem;
      margin-left: 2.5rem;
      ::v-deep .el-input__inner {
        background-color: transparent;
        background-image: none;
        border-radius: 1.5625rem;
        border: 0.0625rem solid #dcdfe6;
        color: white;
      }
    }
  }
}
</style>
