<template>
  <div class="app-content">
    <!-- 导航 -->
    <div class="indexCenten">
      <div class="topContent">
        <!-- 轮播 -->
        <div
          class="backCarousel"
          :style="{
            width: '100%',
            background: '#eee',
          }"
          v-if="this.financeFileVOList.length > 0"
        >
          <!-- 轮播图 -->
          <el-carousel
            style="text-align: center"
            v-if="fileType == 0"
            ref="carousel"
            height="100vh"
            trigger=""
            width="100%"
            :interval="bannerTime * 1000"
            loop
            autoplay
            indicator-position="none"
            @mouseenter.native="delHandleMouseEnter"
          >
            <el-carousel-item
              v-for="labe in financeFileVOList"
              :key="labe.fileId"
            >
              <img
                :src="imgUrl + labe.fileUri"
                @click="navigateToLink(labe, chiIndex)"
                mode
              />
            </el-carousel-item>
          </el-carousel>
          <!-- 视频 -->
          <video
            v-else
            ref="videoPlayer"
            id="myVideo"
            loop
            style="
              height: 100vh;
              width: 100%;
              position: relative;
              object-fit: cover;
            "
            :muted="Videomuted"
            autoplay
            :controls="false"
          >
            <source
              :src="imgUrl + financeFileVOList[0].fileUri"
              type="video/mp4"
            />
          </video>
          <!-- <button @click="togglePlay">Toggle Play</button> -->
        </div>
        <!-- 默认轮播图 -->
        <el-carousel
          style="text-align: center"
          class="carousel-center-image"
          ref="carousel"
          trigger=""
          width="100%"
          height="100vh"
          :interval="bannerTime * 1000"
          loop
          autoplay
          indicator-position="none"
          v-if="this.financeFileVOList.length == 0"
        >
          <el-carousel-item
            v-for="(ist, list) in theRotationChartDefaults"
            :key="list"
          >
            <img :src="ist.fileUri" />
          </el-carousel-item>
        </el-carousel>
        <!-- 快捷导航 -->
      </div>

      <div class="bottomNavigat">
        <div class="bottomNavigat_box">
          <div class="clickPerItem">
            <img
              src="../../../../assets/allImage/homePage/icon_kuaijie@2x.png"
              alt=""
            />
            <span>快捷导航栏</span>
          </div>
          <div
            class="perItem pointer"
            v-for="(item, index) in bottomList"
            :key="index"
            @click="clickBottom(item)"
          >
            <img
              width="1.875rem"
              :src="imgUrl + item.fileVOS[0].fileUri"
              alt=""
            />
            <span>{{ item.columnsName }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import {
  searchShortcutList,
  searchBanner,
  QuickNavigation,
} from '@/api/HomePage/banner';
import { headNavigationSubcolumn, slideshowList } from '@/api/index';
export default {
  data() {
    return {
      Videomuted: true,
      financeFileVOList: [], //轮播图
      fileType: '', //类型
      // 默认轮播图
      theRotationChartDefaults: [
        {
          fileUri: require('../../../../assets/allImage/defaultCarouselChart/banner2@1x (1).png'),
        },
        // {
        //   fileUri: require('../../../../assets/allImage/defaultCarouselChart/toutu_renliziyuan@1x.png'),
        // },
        // {
        //   fileUri: require('../../../../assets/allImage/defaultCarouselChart/toutu_xinwenzhognxin@1x.png'),
        // },
        // {
        //   fileUri: require('../../../../assets/allImage/defaultCarouselChart/toutu_zhaobiaoxinxi@1x.png'),
        // },
        // {
        //   fileUri: require('../../../../assets/allImage/defaultCarouselChart/toutu_zhuantizhuanlan@1x.png'),
        // },
        // {
        //   fileUri: require('../../../../assets/allImage/defaultCarouselChart/toutu-fuwuwangdain@1x.png'),
        // },
      ],
      URL: '',
      src: '',
      videoSource: 'http://vjs.zencdn.net/v/oceans.mp4',
      screenWidth: 0,
      screenHeight: 0,
      activeIndex: '1',
      activeIndex2: '1',
      bannerList: [],
      defimgUrl: require('../../../../assets/allImage/homePage/icon_wangdain@2x.png'),
      bottomList: [],
      bottomState: -1,
      imgUrl: '',
      picList: [],
      slideshowList: [], // 轮播图
      queryParams: {
        pageNum: 1, //当前页数page
        pageSize: 10,
        columnId: '',
      },
    };
  },
  computed: {
    ...mapState(['bannerTime']),
  },
  methods: {
    togglePlay() {
      if (this.$refs.videoPlayer.muted == false) {
        this.$refs.videoPlayer.muted = true;
      } else {
        this.$refs.videoPlayer.muted = false;
      }
      console.log('ddddd', this.$refs.videoPlayer.muted);
    },
    // 快捷导航
    async getShortList() {
      this.bottomList = [];
      const res = await QuickNavigation();
      if (res.data.length > 0) {
        this.bottomList = res.data;
      }
    },

    handleSelect(key, keyPath) {},

    updateScreenSize() {
      this.screenWidth =
        window.innerWidth || document.documentElement.clientWidth;
      this.screenHeight =
        window.innerHeight || document.documentElement.clientHeight;
    },
    // 快捷导航
    async clickBottom(item) {
      // item.isRegular  0为动态 1司概况 2首页 3、ETC服务网点 5为etc生活
      if (item.isRegular == 1) {
        this.$store.state.dynamicParentName = {};
        this.$store.state.commonNavigationList = [];
        this.$store.state.secondLevel.columnsName = '';
        this.$store.state.dynamicParentName = item;
        const towRes = await headNavigationSubcolumn({ id: item.id });
        if (towRes.data.length) {
          this.$store.state.commonNavigationList = towRes.data; // 二级公共导航数据
          if (towRes.data.length > 0) {
            this.$store.state.secondLevel.columnsName =
              towRes.data[0].columnsName;
          }
        }
        this.$router.push({
          path: '/layout/companyProfile',
          query: {
            id: towRes.data[0].id,
            columnPageType: towRes.data[0].columnPageType,
            isRegular: towRes.data[0].isRegular,
            paramID: towRes.data[0].parentId,
            columnPageStyle: towRes.data[0].columnPageStyle,
            isSubStyle: towRes.data[0].isSubStyle,
            columnTheme: towRes.data[0].columnTheme,
          },
        });
        // 请求公司概况接口
        this.$store.dispatch('companyProfileList', towRes.data[0].id);
        this.$store.state.newList = [];
      } else if (item.isRegular == 5) {
        this.$store.state.dynamicParentName = {};
        this.$store.state.dynamicParentName = item;
        this.$store.state.commonNavigationList = {};
        this.$store.state.secondLevel.columnsName = '';
        this.$router.push({
          path: '/layout/etcguide',
          query: { id: item.id },
        }); // 跳转到etc生活
      } else if (item.isRegular == 3) {
        this.$router.push('/layout/serviceNetworkStation'); // 跳转网点
      } else if (item.isRegular == 4) {
        window.open(
          'https://etc-website.gxetc.com.cn/#/login?redirect=%2FetcBusiness',
          '_blank'
        ); // 网上营业厅
      } else {
        // 动态公共模块
        this.$store.state.dynamicParentName = {};
        this.$store.state.commonNavigationList = [];
        this.$store.state.secondLevel.columnsName = '';
        this.$store.state.dynamicParentName = item;
        const CompanyOverview = await headNavigationSubcolumn({ id: item.id });

        if (CompanyOverview.data.length > 0) {
          this.$store.state.secondLevel.id = CompanyOverview.data[0].id;
          this.$store.state.commonNavigationList = CompanyOverview.data; // 二级公共导航数据
          if (CompanyOverview.data.length > 0) {
            this.$store.state.secondLevel.columnsName =
              CompanyOverview.data[0].columnsName;
          }
          // 获取公共轮播图
          this.$store.dispatch(
            'publicRotationChart',
            CompanyOverview.data[0].id
          );
          this.queryParams.columnId = CompanyOverview.data[0].id;
          this.$router.push({
            path: '/layout/companyProfile',
            query: {
              id: CompanyOverview.data[0].id,
              columnPageType: CompanyOverview.data[0].columnPageType,
              isRegular: CompanyOverview.data[0].isRegular,
              columnPageStyle: CompanyOverview.data[0].columnPageStyle,
              paramID: CompanyOverview.data[0].parentId,
              isSubStyle: CompanyOverview.data[0].isSubStyle,
              columnTheme: CompanyOverview.data[0].columnTheme,
            },
          });
        } else {
          // 当一级导航只有单体页时
          if (item.columnPageType == 'MONOMER' && item.columnPageStyle == '') {
            this.$store.state.dynamicParentName = {};
            this.$store.state.dynamicParentName = item;
            this.$store.state.commonNavigationList = [];
            this.$store.state.secondLevel.columnsName = '';
            this.$router.push({
              path: '/layout/companyProfile/monomer',
              query: {
                id: item.id,
                paramID: item.parentId,
                isSubStyle: item.isSubStyle,
              },
            });
            // localStorage.setItem('INDEX_KEY', JSON.stringify(this.couns));
            return;
          } else {
            this.queryParams.columnId = item.id;
            // 如果没有二级导航就默认一级导航跳转页面
            this.$router.push({
              path: '/layout/companyProfile',
              query: {
                id: item.id,
                columnPageType: item.columnPageType,
                columnPageStyle: item.columnPageStyle,
                isRegular: item.isRegular,
                paramID: item.parentId,
                isSubStyle: item.isSubStyle,
                columnTheme: item.columnTheme,
              },
            });
          }
        }
        // 刷新新闻中心页面数据
        this.$store.dispatch('newsListFn', this.queryParams);
        this.$store.state.companyList = [];
      }
    },

    async getBannerList() {
      //  获取轮播图跟视频
      const res = await searchBanner();
      if (res.data.code == 200) {
        if (res.data.data && res.data.data.length > 0) {
          this.fileType = res.data.data[0].fileType;
          res.data.data.map((item) => {
            if (item.financeFileVOList && item.financeFileVOList.length > 0) {
              this.financeFileVOList = item.financeFileVOList;
            }
          });
        }
      }
    },

    navigateToLink(chiItem, chiIndex) {
      if (chiItem.impUrl) {
        window.open(`${chiItem.impUrl}`, '_blank');
      }
    },
    delHandleMouseEnter() {
      // this.$refs.carousel.handleMouseEnter = () => {};
    },
    playVideo() {
      // var video = document.getElementById('myVideo');
      // // 隐藏鼠标移入时的进度条
      // video.addEventListener('mouseover', () => {
      //   video.controls = false;
      // });
      // video.play();
      // video.volume = 1;
      // // 显示鼠标移出时的进度条
      // video.addEventListener('mouseout', () => {
      //   video.controls = true;
      // });
    },
    setUrlvideo() {
      // this.src = 'http://vjs.zencdn.net/v/oceans.mp4';
      this.playVideo();
    },
    getBannerTime() {
      this.$store.dispatch('bannerSpace');
    },
  },
  created() {
    this.imgUrl = process.env.VUE_APP_BASE_URL;
    this.getBannerList(); // 轮播图、视频
    this.getShortList(); // 快捷导航
    this.getBannerTime(); // 轮播时间
  },
  mounted() {
    this.updateScreenSize();
    window.addEventListener('resize', this.updateScreenSize);
    this.setUrlvideo();
  },
};
</script>
<style lang="scss" scoped>
.app-content {
  width: 100%;
  height: 100vh;
  position: relative;
  .indexCenten {
    width: 100%;

    .topContent {
      position: relative;
      // 轮播
      .backCarousel {
        cursor: pointer;
        overflow: hidden;
        ::v-deep img {
          max-width: 100% !important;
        }
        .video {
          max-width: 100%;
        }
      }
      .shortcutNav {
        width: 100%;
        display: flex;
        align-items: center;
      }
    }
    .bottomNavigat {
      /* margin: 0 25%; */
      flex-direction: column;
      align-items: center;
      .bottomNavigat_box {
        position: absolute;
        bottom: 4%;
        left: 50%;
        transform: translate(-50%, 0%);
        display: flex;
        z-index: 122;
        border-radius: 1.25rem;
        background-color: rgba(0, 0, 0, 0.6);
        .perItem,
        .clickPerItem {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 14.375rem;
          padding: 0.9375rem 0;

          img {
            width: 1.875rem;
            margin-bottom: 0.625rem;
          }
          span {
            font-size: 1.125rem;
            color: white;
          }
        }
        .pointer {
          cursor: pointer;
        }
        .clickPerItem {
          background-color: #0aa4ee;
          border-radius: 1.25rem;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          padding: 1.6875rem 0;
          img {
            margin-right: 0.625rem;
            width: 2.5rem;
            margin-bottom: 0rem;
          }
        }
      }
    }
  }
}
</style>
