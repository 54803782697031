import request from "/utils/request";

// 获取网点类型
export function searchTravel(query) {
    return request({
        url: "/v1/travel/information/list/classtype",
        method: "get",
        params:query,
    });
}

// 获取导航列表
export function searchJieTongList() {
    return request({
        url: "/finance/Reception/jieTongList",
        method: "get",
    });
}

// 获取捷通列表轮播
export function searchArticleMultiMedia() {
    return request({
        url: "/finance/Reception/articleMultiMedia",
        method: "get",
    });
}