import request from '/utils/request';

// 新闻列表
export function searchArticlePageList(columnId) {
  return request({
    url: '/finance/Reception/articleJieTong?columnId=' + columnId,
    method: 'get',
  });
}
// 新闻详情
export function journalismNewsDetailsList(id) {
  return request({
    url: '/finance/Reception/articleDetails?id=' + id,
    method: 'get',
  });
}
